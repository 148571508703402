import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import { MiddleSpacedBigDescription, MainTitle } from "../global/common/text"
import { LineWrapperWithBottomSpace } from "../global/common/containers"
import { Button } from "../global/atoms/Button"

export const IndividualApproachInner = styled.article`
  max-width: 1000px;
  margin: -2.5rem auto 0;

  text-align: center;

  @media ${maxD.mobileL} {
    margin: -1rem auto 0;
  }
`

const IndividualApproach = ({
  data: {
    individualApproachGallery: gallery,
    individualApproachTitle: title,
    individualApproachDescription: description,
    individualApproachButton: button,
  },
}) => (
  <LineWrapperWithBottomSpace
    style={{
      "--does-bold-line-exists": "none",
    }}
  >
    <IndividualApproachInner>
      <MainTitle dangerouslySetInnerHTML={{ __html: title }} />
      <MiddleSpacedBigDescription
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Button to={button.url} theme="green" target={button.target}>
        {button.title}
      </Button>
    </IndividualApproachInner>
  </LineWrapperWithBottomSpace>
)

export default IndividualApproach
